<template>
  <div class="wrapper">
    <!-- 适老化改造 -->
    <!-- <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
        <vxe-button status="my-orange" icon="" @click="goPage('add')">下载报告</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="姓名" span="8">
          <vxe-input v-model="searchData.assessorName" placeholder="请输入姓名" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="身份证号" span="8">
          <vxe-input v-model="searchData.serviceItem" placeholder="请输入身份证号" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="评估机构" span="8">
          <vxe-input v-model="searchData.assessOrgId" placeholder="请输入评估机构" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div> -->
    <div>
      <el-button type="text" icon="el-icon-plus" @click="()=>{isplus = true }" v-if="!isplus"></el-button>
      <el-button type="text" icon="el-icon-minus" @click="()=>{isplus = false }" v-if="isplus"></el-button>
      <el-button type="primary" icon="el-icon-plus" @click="addProject()" v-if="isplus">添加改造档案</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="addConfirmInfo()" v-if="isplus">添加确认信息</el-button>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          show-overflow-tooltip
          style="width: 100%">
        <el-table-column align="center"
            type="index"
            width="50"
            label="序号">
        </el-table-column>
        <el-table-column align="center" prop="oldPersonName" label="服务对象">
          <template slot-scope="scope">
            <p class="link-a" @click="goPage('detail', scope.row)">{{ scope.row.oldPersonName }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="oldPersonSex" label="性别"></el-table-column>
        <el-table-column align="center" prop="oldPersonIdNum" label="身份证号"></el-table-column>
        <el-table-column align="center" prop="oldPersonContract" label="联系电话"></el-table-column>
        <el-table-column align="center" prop="oldPersonTypeNames" label="人员类别"></el-table-column>
        <el-table-column align="center" prop="oldPersonAddress" label="申请改造住宅地址"></el-table-column>
        <el-table-column align="center" prop="elderlyDataType" label="服务项目">
          <template slot-scope="scope">
            {{ scope.row.elderlyDataType === 'BASIC_INFO' ? '适老化改造基本信息确认' : '适老化改造档案' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="workerName" label="服务人员">
          <template slot-scope="scope">
            <p class="link-a" @click="toDetail('detail', scope.row)">{{ scope.row.workerName }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center"

            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
            <el-button type="text" size="small" class="el-icon-minus" @click="scope.row.ismore=false"
                       v-if="scope.row.ismore"></el-button>
            <el-button type="text" size="small" class="el-icon-plus" @click="scope.row.ismore=true"
                       v-if="!scope.row.ismore"></el-button>
            <el-button @click="editClick(scope.row)" v-if="scope.row.ismore" type="text" size="small">编辑</el-button>

            <el-button @click="delProject(scope.row)" v-if="scope.row.ismore" type="text" size="small">删除</el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize"
                 :page-sizes="tablePage.pageSizes"
                 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
    </div>

    <el-dialog title="添加适老化改造" :visible.sync="dialogFormVisible" :show-close="false" :close-on-click-modal='false'>
      <el-form :model="form" label-position="left">
        <el-form-item label="姓名" prop="serviceOldPersonId" :label-width="formLabelWidth">
          <el-select filterable v-model="form.oldPersonId" style="width:100%" placeholder="请选择改造对象">
            <el-option
                v-for="item in personList"
                :key="item.id"
                :label="item.personName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务机构" :label-width="formLabelWidth">
          <el-select v-model="form.reformOrgId" placeholder="请选择服务机构" style="width:100%">
            <el-option
                v-for="item in orgDatas"
                :key="item.id"
                :label="item.compName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="类型" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="服务方案" :label-width="formLabelWidth">
          <el-input v-model="form.plan" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="评估员姓名" :label-width="formLabelWidth">
          <el-input v-model="form.assessorName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="初步评估结果" :label-width="formLabelWidth">
          <el-input v-model="form.preEvalResult" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="最终评估结果" :label-width="formLabelWidth">
          <el-input v-model="form.finalEvalResult" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="审核意见" :label-width="formLabelWidth">
          <el-input v-model="form.reviewComments" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="专家意见" :label-width="formLabelWidth">
          <el-input v-model="form.expertComments" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="适老化产品明细" :label-width="formLabelWidth">
          <el-input v-model="form.productDetail" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="请选择状态" style="width:100%">
            <el-option
                label="合格"
                value="合格">
            </el-option>
            <el-option
                label="待验收"
                value="待验收">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="改造前照片" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="beforeImgsList" @uploadChange="onBeforeImg"></upload-img>
        </el-form-item>
        <el-form-item label="改造后照片" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="afterImgsList" @uploadChange="onAfterImg"></upload-img>
        </el-form-item>
        <el-form-item label="评估表，审批表，前后对比档案" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="otherImgsList" @uploadChange="onOtherImg"></upload-img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="()=>{dialogFormVisible = false,form={}}">取 消</el-button>
        <el-button type="primary" @click="addElderBtn()">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="添加适老化改造基本信息" :visible.sync="confirmInDialog" :show-close="false" :close-on-click-modal='false'>
      <el-form :model="confirmInForm" label-position="left">
        <el-form-item label="服务对象" prop="serviceOldPersonId" :label-width="formLabelWidth">
          <el-select filterable v-model="confirmInForm.oldPersonId" style="width:100%" placeholder="请选择服务对象">
            <el-option
                v-for="item in personList"
                :key="item.id"
                :label="item.personName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务人员" :label-width="formLabelWidth">
          <el-select v-model="confirmInForm.workerId" placeholder="请选择服务人员" style="width:100%">
            <el-option
                v-for="item in servicePersonList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="类型" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="confirmInForm.remark" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="水印时间" :label-width="formLabelWidth">
          <el-date-picker
              style="width:100%;"
              v-model="confirmInForm.pcWatermarkTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              @change="pcWatermarkTimeChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="请拍照上传 老人室内照 片（至少三 张）" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="confirmInfoImgList" @uploadChange="confirmInfoImg"></upload-img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="()=>{confirmInDialog = false,confirmInForm={}}">取 消</el-button>
        <el-button type="primary" @click="submitConfirmInfo()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from "axios";

export default {
  components: {
    UploadImg
  },
  data() {
    return {
      form: {},
      searchData: {},
      seachStatus: false,
      dialogFormVisible: false,
      tableData: [],
      beforeImgsList: [],
      afterImgsList: [],
      otherImgsList: [],
      orgDatas: [],
      isplus: false,
      isedit: false,
      personList: [],
      formLabelWidth: '120px',
      // ismore:false,
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      confirmInFormEdit: false,
      servicePersonList: [],
      confirmInForm: {},
      confirmInDialog: false,
      confirmInfoImgList: []
    }
  },
  created() {
    this.getLists()
    this.getPersonList()
    this.querySumCenterList()
    this.getServicePersonList()

  },
  computed: {
    ...mapState([
      "layouts"
    ]),
  },
  methods: {
    ...mapActions([
      "queryolderReformListAll",
      'elderReformDetai',
      'getServeOrgsListServe',
      'addElderReform',
      'updateElderReform',
      'deleteElderReform',
      "getHomePersonListServe",
      'getServeOrgsListServe',
      'getWorkPersonListServe',
      'queryolderReformListAdd',
      'queryolderReformListDelete',
      'queryolderReformListUpdate',
      'queryolderReformListDetail'
    ]),
    querySumCenterList() {
      this.getServeOrgsListServe({
        current: 1,
        size: 9999,
        param: {},
      }).then(res => {
        // console.log(res,'querySumCenterList');
        if (res.code == 200) {
          this.orgDatas = res.data.records
          console.log(this.orgDatas);
        }
        this.loading = false;
      })
    },
    onBeforeImg(data) {
      this.form.beforeImgs = data.join(',')
    },
    onAfterImg(data) {
      this.form.afterImgs = data.join(',')
    },
    onOtherImg(data) {
      this.form.otherImgs = data.join(',')
    },
    addProject() {
      this.isedit = false
      this.dialogFormVisible = true
      this.beforeImgsList = []
      this.afterImgsList = []
      this.otherImgsList = []
    },

    /**
     * 获取服务人员
     */
    getServicePersonList() {
      this.getWorkPersonListServe({
        current: 1,
        size: 4000,
        param: {}
      }).then(res => {
        if (res.code == 200) {
          this.servicePersonList = res.data.records
        }
      })
    },
    /**
     * 添加确认信息按钮
     */
    addConfirmInfo() {
      this.confirmInFormEdit = false;
      this.confirmInDialog = true;
      this.confirmInfoImgList = []
    },

    /**
     * 上传图片
     */
    confirmInfoImg(e) {
      console.log(e);
      this.confirmInForm.imgs = e.join(',');
    },
    /**
     * 水印时间
     */
    pcWatermarkTimeChange(e) {
      console.log(e)
    },
    /**
     * 确认信息提交
     */
    submitConfirmInfo() {
      if (this.confirmInFormEdit == false) {
        let data = this.confirmInForm
        if (!data.hasOwnProperty('imgs')) {
          this.$message.warning("图片最少三张")
          return
        }
        if (data.imgs.length < 3) {
          this.$message.warning("图片最少三张")
          return
        }
        if (!data.hasOwnProperty('needAddWatermarkImgIdSet')) {
          data.needAddWatermarkImgIdSet = []
        }
        let needAddWatermarkImgIdSet = [];
        data.imgs.split(",").forEach(item => {
          if(data.needAddWatermarkImgIdSet.indexOf(item) == -1){
            needAddWatermarkImgIdSet.push(item);
          }
        })
        data.needAddWatermarkImgIdSet = needAddWatermarkImgIdSet
        data.fromPc = true;
        this.queryolderReformListAdd(data).then(res => {
          if (res.code == 200) {
            this.$message.success("修改成功")
            this.confirmInForm = {}
            this.confirmInDialog = false
            this.getLists()
          }
        })
      } else {
        let data = this.confirmInForm
        if (!data.hasOwnProperty('imgs')) {
          this.$message.warning("图片最少三张")
          return
        }
        if (data.imgs.length < 3) {
          this.$message.warning("图片最少三张")
          return
        }
        if (!data.hasOwnProperty('needAddWatermarkImgIdSet')) {
          data.needAddWatermarkImgIdSet = []
        }
        let needAddWatermarkImgIdSet = [];
        data.imgs.split(",").forEach(item => {
          if(data.needAddWatermarkImgIdSet.indexOf(item) == -1){
            needAddWatermarkImgIdSet.push(item);
          }
        })
        data.needAddWatermarkImgIdSet = needAddWatermarkImgIdSet
        data.fromPc = true;
        this.queryolderReformListUpdate(data).then(res => {
          if (res.code == 200) {
            this.$message.success("修改成功")
            this.confirmInForm = {}
            this.confirmInDialog = false
            this.getLists()
          }
        })
      }
    },

    // 获取服务对象
    getPersonList() {
      let params = {
        current: 1,
        size: 4000,
        param: {}
      }
      this.getHomePersonListServe(params).then(res => {
        if (res.code == 200) {
          this.personList = res.data.records;
        }
      })
    },
    // queryDetail(id){
    //   let params = {
    //     id:id
    //   }
    //   this.elderReformDetai(params).then(res=>{
    //     console.log(res);
    //     if(res.code==200){
    //       if(res.data==null){
    //         this.isshow =false
    //       }else{
    //         this.isshow =true
    //         this.table = {
    //           ...res.data
    //         }
    //         this.id = res.data.id
    //       }

    //     }
    //   }).catch(err=>{
    //     console.log(err);
    //   })

    // },
//查看图片详情
    getFileDetail(ids, type) {
      this.beforeImgsList = []
      this.afterImgsList = []
      this.otherImgsList = []
      this.confirmInfoImgList = []
      ids = ids.split(',')
      console.log(ids, type);
      ids.forEach(item => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            'Authorization': sessionStorage.token,
          },
        }).then((res) => {
          if (type == 1) {
            this.beforeImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else if (type == 2) {
            this.afterImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else if (type == 3) {
            this.otherImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else {
            this.confirmInfoImgList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          }
        })
      })
    },
    //添加确定
    addElderBtn() {
      if (this.isedit) {
        let params = this.form
        this.updateElderReform(params).then(res => {
          if (res.code == 200) {
            this.$message.success("修改成功")
            this.form = {}
            this.dialogFormVisible = false
            this.getLists()

          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        let params = this.form
        this.addElderReform(params).then(res => {
          if (res.code == 200) {
            console.log(res, '添加');
            this.$message.success("添加成功")
            this.form = {}
            this.dialogFormVisible = false
            this.getLists()


          }
        }).catch(err => {
          console.log(err);
        })
      }

    },

    editClick(row) {
      if (row.elderlyDataType === 'BASIC_INFO') {
        this.confirmInFormEdit = true;
        this.queryolderReformListDetail({ id: row.id }).then(res => {
          if (res.code == 200) {
            this.confirmInForm = {
              id: res.data.id,
              oldPersonId: res.data.oldPersonId,
              imgs: res.data.imgs,
              workerId: res.data.workerId,
              remark: res.data.remark,
              needAddWatermarkImgIdSet: res.data.imgs
            }
            if (this.confirmInForm.imgs) {
              this.getFileDetail(this.confirmInForm.imgs, 4)
            }
          }
          this.confirmInDialog = true
        })
      } else {
        this.isedit = true
        let params = {
          id: row.id
        }
        this.elderReformDetai(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.isshow = true
            this.form = {
              ...res.data
            }
            console.log(this.form.beforeImgs);
            if (this.form.beforeImgs) {
              console.log(123213);
              this.getFileDetail(this.form.beforeImgs, 1)
            }
            if (this.form.afterImgs) {
              this.getFileDetail(this.form.afterImgs, 2)
            }
            if (this.form.otherImgs) {
              this.getFileDetail(this.form.otherImgs, 3)
            }
          }
        }).catch(err => {
          console.log(err);
        })

        this.dialogFormVisible = true
      }
    },
    searchEvent() {
      alert('查询')
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool;
    },
    searchReset() {
      this.searchData = {};
      this.tablePage.currentPage = 1
      this.getLists();
    },
    // 分页功能
    pageChange(item) {
      if (item.type == "size") {
        this.tablePage.currentPage = 1;
      } else {
        this.tablePage.currentPage = item.currentPage;
      }
      this.getLists();
    },
    //查询列表
    getLists() {
      let params = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }
      this.queryolderReformListAll(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.dataList
          this.tablePage.totalResult = res.data.total
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i], 'ismore', false)
          }
          console.log(this.tableData);
        }
      })
    },
    //删除
    delProject(row) {
      if (row.elderlyDataType === 'BASIC_INFO') {
        this.$XModal.confirm("您确定要删除该数据?").then(type => {
          if (type === "confirm") {
            let params = row.id
            this.queryolderReformListDelete(params).then(res => {
              console.log(res);
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
                this.getLists()

              }
            })
          } else {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          }
        })
      } else {
        this.$XModal.confirm("您确定要删除该数据?").then(type => {
          if (type === "confirm") {
            let params = row.id
            this.deleteElderReform(params).then(res => {
              console.log(res);
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
                this.getLists()

              }
            })
          } else {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          }
        })
      }
    },
    //查看
    handleClick(row) {
      this.$router.push({
        name: "oldChangeDetail",
        query: {
          id: row.id,
          elderlyDataType: row.elderlyDataType
        },
      });
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddPerson',
        params: {
          type: type,
          id: itemData.oldPersonId,
        },
        query: {
          oldPersonId: itemData.oldPersonId
        }
      })
    },

    toDetail(type, itemData) {
      itemData.id = itemData.workerId
      this.$router.push({
        name: 'WorkPersonDetail',
        params: {
          type: type,
          id: itemData.workerId,
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
          workPersonId: type == 'add' ? 'add' : itemData.workerId
        },
      })
    },
  }

}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100% !important;
}

v-deep .el-form-item__label {
  text-align: left !important;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
</style>
